import React from 'react'
import PropTypes from 'prop-types'
// import { v4 } from 'uuid'


// const BulletList = ({points}) => {
//   return (
//     <ul>
//       {(points || []).map(p =>
//         <li className="is-size-5" key={v4()} style={{
//           backgroundImage: `url('/img/bullet.svg')`,
//           backgroundSize: '8px 8px',
//           backgroundRepeat: 'no-repeat',
//           backgroundPosition: '12px 11px',
//           padding: '0 0 0 2rem'
//         }}>
//           {p.text}
//         </li>
//       )}
//     </ul>
//   );
// }

const StepsGrid = ({ gridItems }) => {
  return (
    <div className="columns is-multiline">
      {gridItems.map(item => (
        <section key={item.text} className="column is-6" style={{margin: '0', paddingTop: '1rem', paddingBottom: '1rem'}}>
          <p
            className="is-size-5 is-size-6-mobile has-text-weight-semibold"
            style={{
              color: '#FF0077',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {item.increment}
          </p>
          <p className="is-size-4 is-size-5-mobile has-text-weight-semibold" style={{marginBottom: '1rem'}}>{item.text}</p>
          <p className="is-size-5 is-size-6-mobile" style={{marginBottom: '1rem'}}>{item.description}</p>
          {/* <BulletList points={item.points}/> */}
        </section>
      ))}
    </div>
  )
}

StepsGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      increment: PropTypes.string,
      text: PropTypes.string,
      description: PropTypes.string,
      // points: PropTypes.arrayOf(
      //   PropTypes.shape({
      //     string: PropTypes.string,
      //   })
      // )
    })
  ),
}

export default StepsGrid
