import React from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { v4 } from 'uuid'

const QuotesGrid = ({ quotes, theme }) => {
  const isLight = theme === 'light'
  return (
    <div className="">
      {quotes.map(item => (
        <div key={v4()} className="content" style={{margin: '48px 0'}}>
          <p
            className={classes({
              'has-text-weight-light': true,
              'has-text-centered': !isLight,
              'is-italic': true,
              'is-size-4 is-size-5-mobile': true,
            })}
            style={{
              color: isLight ? '#C6C6C6': '#2E4F5B',
            }}
          >
            "{item.quote}"
          </p>
          <div className={classes({
            'is-size-4 is-size-6-mobile': true,
            'has-text-centered': !isLight,
            'has-text-weight-bold': true,
          })} style={{color:'#D2D5E3'}}>
            {item.image && item.image.childImageSharp ? <PreviewCompatibleImage imageInfo={item.image} style={{width: 200, margin: 'auto'}} /> : item.author}
          </div>
        </div>
      ))}
    </div>
  )
}
QuotesGrid.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
      date: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
}

export default QuotesGrid
