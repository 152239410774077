import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Select } from 'antd';


import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import Quotes from '../components/Quotes'
import Steps from '../components/Steps'
import partners from '../img/partners.svg';
import partnersMobile from '../img/partners-mobile.svg';
import star1 from '../img/star-1.svg';
import star2 from '../img/star2.svg';

class ProjectCategorySelector extends React.Component {
  constructor(props) {
    super(props);
    const roles = (props.search.roles || '').split(',');
    this.state = {
      projectCategory: roles[0],
      roles
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange (value) {
    this.setState({'projectCategory': value})
  }
  render() {
    const {projectCategory} = this.state;
    const {search} = this.props;
    return (
      <form action="/talent" method="GET">
        <div className="columns is-vcentered">
          <div className="column is-3 is-size-6 is-size-5-desktop has-text-right-desktop">
            Find celebrities for your:
          </div>
          <div className="column is-size-5">
            <Select
              style={{ width: '100%' }}
              placeholder={projectCategory}
              size={'large'}
              defaultValue={projectCategory}
              onChange={this.handleChange}
            >
              {(search.roles || '').split(',').map(role => <Select.Option key={`role_${role}`} style={{ borderBottom: '1px dotted #666'}}>{role}</Select.Option>)}
            </Select>
          </div>
          <div className="column is-narrow">
            <Link to={`/talent?category=${projectCategory}`} className="button is-medium is-danger block" style={{maxHeight:40, width:'100%'}}>Search now</Link>
          </div>
        </div>
      </form>
    )
  }
}

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  search,
  subheading,
  steps,
  mainpitch,
  testimonials,
}) => {
  return (
    <div>
      <section className="section" style={{position: 'relative', overflow: 'hidden'}}>
        <div className="container">
          <div className="column" style={{padding:'0 0 64px 0'}}>
            <div
              className="margin-top-0"
              style={{
                backgroundImage: `url(${
                  !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                })`,
                backgroundPosition: `right top`,
                backgroundSize: 'auto 100%',
                backgroundRepeat: 'no-repeat',
                height: 448,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  maxWidth: '660px',
                  lineHeight: '1.2',
                  justifyContent: 'space-around',
                  alignItems: 'left',
                  flexDirection: 'column',
                  marginTop: -64,
                  background: 'rgba(255,255,255,.9)',
                  paddingTop: '2rem',
                  paddingBottom: '2rem'
                }}
              >
                <h4 className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                  style={{
                    color: '#FF0077',
                    fontStyle: 'italic',
                    padding: '0',
                  }}>
                  {title}
                </h4>
                <h1
                  className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                  style={{
                    color: '#262930',
                    padding: '0',
                  }}
                >
                  {heading}<span style={{color: '#FF0077'}}>.</span>
                </h1>
                <h3
                  className="is-size-6-mobile is-size-6-tablet is-size-5-widescreen"
                  style={{
                    color: '#262930',
                    padding: '0',
                    lineHeight: 1.5,
                    maxWidth: 500
                  }}
                >
                  {subheading}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <img src={star1} alt="The Casting Agency" style={{
          width: '432px',
          maxHeight: '100%',
          position: 'absolute',
          bottom: -150,
          left: -100,
        }} />
      </section>
      <section className="section" style={{
        backgroundColor: '#272A31',
        backgroundImage: `url('/img/blog-section.svg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className="container" style={{marginTop:-84}}>
          <div
            className="columns is-vcentered"
            style={{
              background: '#F8F8F8',
              zIndex: 2,
              borderRadius: 3,
              padding: '0 1rem',
            }}>
            <div className="column is-12">
              <ProjectCategorySelector  search={search} />
            </div>
          </div>
        </div>
        <div className="container" style={{paddingTop: 132, paddingBottom: 88}}>
          <div className="columns">
            <div className="column is-12">
              <BlogRoll />
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient" style={{position:'relative', paddingTop:'6rem', paddingBottom: '0', overflow: 'hidden', maxWidth: '100%'}}>
        <div className="container">
          <div className="columns">
            <div className="column is-offset-2 is-8 has-text-centered">
              <h2 className="is-size-3 is-size-5-mobile">{mainpitch.title}</h2>
              <p className="is-size-4 is-size-6-mobile" style={{maxWidth: 860, margin:'auto', marginTop:'2rem'}}>
                {mainpitch.description}
              </p>
              <p style={{borderBottom: '1px dotted #D2D5E3', paddingTop: '2rem', paddingBottom: '6rem'}}>
                <Link to={'/about'} className="has-text-danger is-size-6-mobile is-size-5">About us</Link>
              </p>
            </div>
          </div>
        </div>
        <img src={star2} alt="The Casting Agency" style={{
          width: '462px',
          maxHeight: '100%',
          position: 'absolute',
          left: -180,
          top: -100,
          transform: 'rotate(-43deg)'
        }} />
      </section>
      <section className="section section--gradient" style={{position:'relative', paddingTop:'6rem', paddingBottom: '6rem', overflow: 'hidden', maxWidth: '100%'}}>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <h2 className="is-size-3 is-size-5-mobile">{steps.heading}</h2>
              <h3 className="is-size-4 is-size-6-mobile" style={{marginBottom: 32}}>{steps.description}</h3>
              <Steps gridItems={steps.blurbs} />
            </div>
            <div className="column is-4">
              <div style={{
                boxShadow: '0 0 48px rgba(1,1,1,.1)',
                textAlign: 'center',
                margin: '0 0 0 auto',
                maxWidth: 388,
                padding: '6rem 2rem',
                background: '#fff',
              }}>
                <h2 className="is-size-3 is-size-5-mobile">Your Booking</h2>
                <p className="is-size-5 is-size-6-mobile" style={{ maxWidth: 224, margin: '32px auto' }}>
                  Tell us about your project and how we can help
                </p>
                <Link to={'/bookings'} className="button is-danger is-medium">Start Booking</Link>
              </div>
            </div>
          </div>
        </div>
        <img src={star2} alt="The Casting Agency" style={{
          width: '232px',
          maxHeight: '100%',
          position: 'absolute',
          top: 150,
          right: -60,
        }} />
      </section>
      <section className="section" style={{
        backgroundColor: '#272A31',
        backgroundImage: `url('/img/blog-section.svg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className="container">
          <img src={partners} alt="partners" className="is-hidden-touch" style={{ width: "100%", maxHeight: '100%', padding: '54px 0' }} />
          <img src={partnersMobile} alt="partners" className="is-hidden-desktop" style={{ width: "100%", maxHeight: '100%', padding: '0' }} />
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <Quotes quotes={testimonials.quotes}/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  search: PropTypes.object,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  steps: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.shape({
    quotes: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  console.log(frontmatter)
  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        search={frontmatter.search}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        steps={frontmatter.steps}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        search {
          roles
          genres
        }
        mainpitch {
          title
          description
        }
        description
        steps {
          blurbs {
            increment
            text
            description
          }
          heading
          description
        }
        testimonials {
          quotes {
            quote
            author
            image {
              childImageSharp {
                fluid(maxWidth: 200, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
